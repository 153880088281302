// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isArr } from '@/utils';
import initialState from '../initialState';
import store from '..';

const pepperApi = apiClient.merchy.pepper;

/**
 * @description Is valid
 * @param pepperData
 * @returns {boolean}
 */
const isValid = (pepperData) => {
  const { merchants, groups, events } = pepperData;

  return isArr(merchants) && isArr(groups) && isArr(events);
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial pepper state');
  }

  const { merchants, groups, events } = initialState;
  return {
    merchants,
    groups,
    events,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  merchants: ({ merchants }) => merchants,
  groups: ({ groups }) => groups,
  events: ({ events }) => events,
};

const actions = {
  clearMerchants: ({ commit, state }) => commit('SET', {
    ...state,
    merchants: [],
  }),
  clearGroups: ({ commit, state }) => commit('SET', {
    ...state,
    groups: [],
  }),
  getThread: (context, query) =>
    pepperApi.getThread(query)
      .then((res) => res.data.data)
      .catch(err => err),
  getThreads: (context, query) =>
    pepperApi.getThreads(query)
      .then((res) => res.data.data)
      .catch(err => err),
  getMerchants: ({ commit, state }, query) => {
    store.dispatch('pepper/clearMerchants');
    return pepperApi.getMerchants(query)
      .then((res) => {
        const nextItems = res.data.data;

        const nextState = {
          ...state,
          merchants: nextItems,
        };

        commit('SET', nextState);
        return nextItems;
      }).catch(e => Promise.reject(e));
  },
  getGroups: ({ commit, state }, query) => {
    store.dispatch('pepper/clearGroups');
    return pepperApi.getGroups(query)
      .then((res) => {
        const { data } = res.data;
        const nextItems = {
          groups: data,
        };

        const nextState = {
          ...state,
          ...nextItems,
        };

        commit('SET', nextState);
        return nextItems;
      }).catch(e => Promise.reject(e));
  },
  getEvents: ({ commit, state }, query) =>
    pepperApi.getEvents(query).then((res) => {
      const nextEvents = res.data.data;

      const nextState = {
        ...state,
        events: nextEvents,
      };

      commit('SET', nextState);

      return nextEvents;
    }).catch(err => err),
  reset: ({ commit }) => commit('SET', initialState.pepper),
  set: ({ commit }, pepperData) => {
    commit('SET', pepperData);
  },
};

const mutations = {
  SET(state, pepper) {
    /* eslint-disable no-param-reassign */
    state.merchants = pepper.merchants;
    state.groups = pepper.groups;
    state.events = pepper.events;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
